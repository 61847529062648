<template>
  <div class="login-wrapper">
    <div class="login-container">
      <div class="form-layer">
        <div class="form-container">
          <div class="title">
            <img :src="logo" style="width: 40px;" />
            <div class="title-text">
              <div class="main-title">
                KubeStar
              </div>
              <div class="desc">Bring Cloud Native to Enterprises</div>
            </div>

            <language-select class="set-language" />
          </div>

          <div class="login-form-title">
            <span v-if="type == 'register'">{{ $t("registerBtn") }}</span>
            <span v-else>{{ $t("loginBtn") }}</span>
          </div>

          <register v-if="type === 'register'" @toLogin="type = 'login'" />

          <div v-if="type === 'login'">
            <el-form v-if="!validateMFA" ref="loginForm" :model="loginForm" status-icon :rules="loginRules">
              <el-form-item prop="userName">
                <el-input
                  :placeholder="$t('enterUserName')"
                  data-cy="userName"
                  prefix-icon="el-icon-user-solid"
                  v-model="loginForm.userName"
                ></el-input>
              </el-form-item>

              <el-form-item prop="password">
                <el-input
                  :placeholder="$t('enterPassword')"
                  prefix-icon="el-icon-lock"
                  data-cy="password"
                  type="password"
                  show-password
                  v-model="loginForm.password"
                  @keyup.enter.native="handleLogin"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  :loading="loading"
                  type="primary"
                  @click.native.prevent="handleLogin"
                  data-cy="submit"
                  size="small"
                >
                  {{ $t("loginBtn") }}
                </el-button>

                <el-button @click.native.prevent="type = 'register'" size="small">
                  {{ $t("toRegister") }}
                </el-button>
              </el-form-item>
            </el-form>

            <need-MFA ref="needMFA" v-if="validateMFA" @cancel="validateMFA = false" @toLogin="toMFALogin" />
          </div>
        </div>
      </div>

      <div class="introduce-container">
        <div>
          <div class="title">Welcome to KubeStar</div>
          <div class="desc">
            {{ $t("aboutUs") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "api/user";
import logo from "@/assets/logo.svg";
import Register from "./Register";
import NeedMFA from "./NeedMFA";

export default {
  name: "Login",

  components: {
    Register,
    NeedMFA
  },

  data() {
    return {
      logo,
      type: "login",
      loginForm: {
        userName: "",
        password: ""
      },
      loginRules: {
        userName: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        password: { required: true, message: this.$t("rules.require"), trigger: "blur" }
      },
      loading: false,
      validateMFA: false
    };
  },

  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true;
          let response = await login(this.loginForm);
          if (response.code === 0) {
            if (this.needMFA) {
              if (response.data.mfaEnableFlag) {
                this.validateMFA = true;
              } else {
                await this.login({});
              }
            } else {
              await this.login({});
            }
          }
          this.loading = false;
        } else {
          return false;
        }
      });
    },

    async toMFALogin(data) {
      this.$refs["needMFA"].loading = true;
      let code = await this.login({ "X-KubeStar-MFA": data });
      if (code != 0) {
        this.validateMFA = false;
      }

      this.$refs["needMFA"].loading = false;
    },

    async login(headers) {
      let response = await login(this.loginForm, headers);

      if (response.code === 0) {
        this.$store
          .dispatch("app/setAppInfo", {
            ...response.data
          })
          .then(() => {
            this.$router.push({ path: "/home" }).catch(err => {
              if (err) throw new Error(`Login Error: ${err}.`);
            });
          });
      }

      return response.code;
    }
  },

  computed: {
    needMFA() {
      return this.$store.state.config.configs.MFA;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.introduce-container {
  width: 380px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to bottom, #1488cc, #2b32b2);
  z-index: 999;

  & > div {
    color: white;
    text-align: center;
    padding: 0 20px;
    width: 380px;
    box-sizing: border-box;
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .title {
      line-height: 40px;
      font-size: 26px;
      color: #fff;
      font-weight: normal;
      margin-bottom: 20px;
    }

    .desc {
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 40px;
    }
  }
}

.form-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 620px;
  height: 100%;
  @include flex(center);

  .form-container {
    width: 500px;
    .title {
      color: $color-main;
      margin-bottom: 40px;
      font-weight: 700;
      @include flex(flex-start, center);

      .title-text {
        margin-left: 10px;
        .main-title {
          color: $color-main;
          font-weight: 700;
          font-size: 24px;
          display: inline-block;
        }
        .desc {
          color: $color-sub;
          font-size: 14px;
        }
      }

      .set-language {
        position: absolute;
        top: 16px;

        right: 5px;
        cursor: pointer;
      }
    }

    .login-form-title {
      color: $color-sub;
      margin-bottom: 20px;

      font-size: 18px;
    }
  }
}

.login-wrapper {
  height: 100vh;
  width: 100%;
  background-color: #eaf1fb;
  background-image: url("../../assets/login-background.png");
  background-size: cover;
  overflow: hidden;
  position: relative;

  .login-container {
    position: relative;
    background: #fff;
    border-radius: 8px;
    width: 1000px;
    height: 580px;
    position: absolute;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
</style>
