<template>
  <el-form ref="registerForm" status-icon :model="registerForm" :rules="registerFormRules">
    <el-form-item prop="email">
      <el-input :placeholder="$t('enterEmail')" prefix-icon="el-icon-message" v-model="registerForm.email"></el-input>
    </el-form-item>

    <el-form-item prop="userName">
      <el-input
        :placeholder="$t('enterUserName')"
        prefix-icon="el-icon-user-solid"
        v-model="registerForm.userName"
      ></el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input
        :placeholder="$t('enterPassword')"
        prefix-icon="el-icon-lock"
        type="password"
        show-password
        v-model="registerForm.password"
      >
      </el-input>
      <span class="help">{{ $t("rules.login.password") }}</span>
    </el-form-item>

    <el-form-item prop="confirmPassword">
      <el-input
        :placeholder="$t('passwordConfirm')"
        prefix-icon="el-icon-lock"
        type="password"
        show-password
        v-model="registerForm.confirmPassword"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button :loading="loading" type="primary" @click.native.prevent="register" size="small">
        {{ $t("registerBtn") }}
      </el-button>

      <el-button @click.native.prevent="$emit('toLogin')" size="small">
        {{ $t("toLogin") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { register } from "api/user";

export default {
  data() {
    let validatePassConfirm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("rules.login.passwordConfirm")));
      } else {
        if (value !== this.registerForm.password) {
          callback(new Error(this.$t("rules.login.passwordDiff")));
        }
        callback();
      }
    };

    return {
      loading: false,
      registerForm: {
        userName: "",
        password: "",
        confirmPassword: "",
        email: ""
      },
      registerFormRules: {
        email: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          { type: "email", message: this.$t("rules.login.email"), trigger: ["blur", "change"] }
        ],
        userName: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z][a-z0-9-_.]*$/,
            message: this.$t("rules.login.userName"),
            trigger: ["blur", "change"]
          }
        ],
        password: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^(?=.*[a-z])(?=.*\d)[^]{6,}$/,
            message: this.$t("rules.login.password"),
            trigger: ["blur", "change"]
          }
        ],
        confirmPassword: { required: true, validator: validatePassConfirm, trigger: "blur" }
      }
    };
  },

  methods: {
    register() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.loading = true;
          register(this.registerForm).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$notify({
                title: "Success",
                message: "Success",
                type: "success"
              });

              this.$emit("toLogin");
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.password-container {
  span {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
</style>
