<template>
  <el-form ref="mfaForm" status-icon :model="mfaForm">
    <el-form-item
      prop="code"
      label="MFA 代码"
      :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
    >
      <el-input v-model="mfaForm.code" @keyup.enter.native="login"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="login" :loading="loading">
        {{ $t("loginBtn") }}
      </el-button>

      <el-button size="small" @click="cancel">
        {{ $t("handle.cancel") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      mfaForm: {
        code: ""
      },

      loading: false
    };
  },

  methods: {
    login() {
      this.$refs.mfaForm.validate(valid => {
        if (valid) {
          this.$emit("toLogin", this.mfaForm.code);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style></style>
